//WhatOthersSay.js file

import React from 'react'
import { useEffect, useState, useRef } from 'react'

//assets
import instaIcon from '../../../assets/images/icon-instagram.svg'

import { isElementInViewport } from '../../../components/functions'

export const WhatOthersSay = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)
    const data = slice.primary;

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const clickDatalayer = (data) => {
        //Data layer - Social post clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }
    
    return(
        <section
            className={(isInView)? 'what-others-say d-flex justify-content-center align-items-center py-lg-5 in-view' : 'what-others-say d-flex justify-content-center align-items-center py-lg-5'}
            ref={ref}>
            <div className='container pt-5 py-lg-5 my-5'>
                <h3 className='text-center d-flex align-items-center text-uppercase'><span className='px-3'>{(data.section_title.text)? data.section_title.text : "What Others Say"}</span></h3>
                <div className='row what-others-say__content py-3 py-lg-5 mt-lg-5 justify-content-center align-items-center text-center'>
                    {/* Display three testimonials only */}
                    {slice.items.map((item, idx) => {
                        if(idx < 3){
                            return (
                                // Each testimonial item
                                <div className={'col-lg-4 testimonial__item py-5 py-lg-0 slide-up animation-delay-' + idx} key={'item-' + idx}>
                                    {(item.name.text)?
                                    <h6 className='name pb-2'>
                                        <span>{item.name.text.split(' ')[0]}</span>
                                        {item.name.text.replace(item.name.text.split(' ')[0], '')}
                                    </h6>
                                    : null}
                                    {(item.subtitle.text)?
                                    <div className='product pb-3'>{(item.subtitle.text).toUpperCase()}</div>
                                    : null}
                                    {(item.description.text)?
                                    <div className="each-testimonial" dangerouslySetInnerHTML={{ __html : item.description.html }}/>
                                    : null}
                                    {(item.instagram_link.url)?
                                        //Check if it's instagram link
                                        (item.instagram_link.url.includes('instagram'))?
                                        <a 
                                            href={item.instagram_link.url} 
                                            target={(item.instagram_link.target)? item.instagram_link.target : "_self"} 
                                            rel="nofollow noreferrer"
                                            onClick={(e) => {
                                                if(item.instagram_link.target === null || item.instagram_link.target === "_self"){
                                                    e.preventDefault();
                                                }
                                                var dl = {
                                                    "event": "social_icon_clicked",
                                                    "social_account": item.instagram_link.url,
                                                    "social_platform": "instagram",
                                                    "page_location": "what others say section"
                                                }

                                                if(item.instagram_link.target === null || item.instagram_link.target === "_self"){
                                                    dl.eventCallback = function(){
                                                        window.location = item.instagram_link.url;
                                                    }
                                                }
                                                clickDatalayer(dl);
                                            }}
                                            onKeyDown={(e) => {
                                                if(item.instagram_link.target === null || item.instagram_link.target === "_self"){
                                                    e.preventDefault();
                                                }
                                                var dl = {
                                                    "event": "social_icon_clicked",
                                                    "social_account": item.instagram_link.url,
                                                    "social_platform": "instagram",
                                                    "page_location": "what others say section"
                                                }

                                                if(item.instagram_link.target === null || item.instagram_link.target === "_self"){
                                                    dl.eventCallback = function(){
                                                        window.location = item.instagram_link.url;
                                                    }
                                                }
                                                clickDatalayer(dl);
                                            }}
                                        >
                                            <div className='insta-link pt-3'>
                                                <img src={instaIcon} alt="Instagram" className='mx-2' />
                                                <span>FOLLOW</span>
                                            </div>
                                        </a>
                                        :
                                        <a href={item.instagram_link.url} target={(item.instagram_link.target)? item.instagram_link.target : "_self"} rel="nofollow noreferrer">
                                            <div className='insta-link pt-3'>
                                                <span>READ ARTICLE</span>
                                            </div>
                                        </a>
                                    : null}
                                </div>
                            )
                        }

                        return null
                    })}
                </div>
            </div>
        </section>
    )
}